<template>
  <div class="control-models">
    <div class="wrap" v-for="(itemType, indexType) in filteredModelType" :key="indexType">
      <div class="wrap-label">
        <span>{{ itemType }}</span>
      </div>
      <template>
        <ul class="flex flex-wrap pt10 pb10">
          <li
            v-for="(item, index) in $initializing"
            :key="index"
            class="item"
            :data-component="item.component"
            draggable
            @dragstart="handleDragStart"
            @dragend="dragEnd"
            v-show="isShowModel(item, itemType)"
          >
            <i class="icon iconfont svg" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { getActivityDetail } from "@/api/activity";

export default {
  inject: ["control"],
  data() {
    return {
      activityInfo: null,
    };
  },
  computed: {
    /** 一些页面需要隐藏部分模块 */
    filteredModelType() {
      const order = [""];
      const { query } = this.$route;
      return this.$modelType.filter((item) => {
        if (query[item] === null) {
          return false;
        }
        return true;
      });
    },
  },
  mounted() {
    console.log("this.$initializing=", this.$initializing);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const resp = await getActivityDetail(this.$route.params.uuid);
      this.activityInfo = resp.data;
    },
    isShowModel(item, itemType) {
      const { query } = this.$route;
      /** 只显示需要的子模块 */
      if (Array.isArray(query[itemType])) {
        return query[itemType].includes(item.name);
      }
      if (item.modelType == itemType) {
        if (item.component == "LuckDraw") {
          return this.activityInfo.lucky_draw;
        }
        if (item.component == "Advert") {
          return false;
        }
        return true;
      }
      return false;
    },
    // 拖拽开始
    handleDragStart(e) {
      console.log("拖拽开始！！！！", e);
      this.control.dragWidget = this.$getNewComponent(e.target.dataset.component);
      this.control.dragstatus = true;
    },
    dragEnd(e) {
      console.log("拖拽结束！！！！", e);
      this.control.dragstatus = false;
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.control-models {
  padding: 20px 20px 10px 20px;
  min-width: 485px;
  position: relative;
  z-index: 30;
  background: #fff;
  height: calc(100% - 40px);
  border-bottom: 1px solid #ebedf0;
  .menu {
    height: 100%;
    // border-right:1px solid #000;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
    p {
      height: 40px;
      line-height: 40px;
      position: relative;
      cursor: pointer;
      &.active {
        color: #2c5acc;
        &::after {
          content: "";
          background: #2c5acc;
          width: 2px;
          height: 26px;
          border-radius: 20%;
          position: absolute;
          top: 7px;
          right: 0;
        }
      }
    }
  }
  .wrap-label {
    padding: 10px 12px;
    background: #e8f0fb40;
    font-size: 14px;
    color: #323233;

    span {
      &::before {
        color: @theme-color;
        content: ".";
        width: 3px;
        height: 10px;
        margin-right: 8px;
        background: @theme-color;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 72px;
    margin-bottom: 8px;
    padding: 10px 0;
    font-size: 12px;
    color: #666;

    i {
      font-size: 29px;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    &:hover {
      color: #fff;
      background: @theme-color;
    }
  }
}
</style>

<style>
.svg {
  display: inline-block;
  margin-bottom: 10px;
  width: 29px;
  height: 34px;
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.icon-park-outline--more-app {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cg fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='4'%3E%3Cpath d='M18 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm0 24H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2ZM42 4H30a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z'/%3E%3Cpath stroke-linecap='round' d='M28 28h16m-8 8h8m-16 8h16'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-park-outline--full-selection {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cg fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='4'%3E%3Cpath d='M34 5H8a3 3 0 0 0-3 3v26a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3Z'/%3E%3Cpath stroke-linecap='round' d='M44 13.002V42a2 2 0 0 1-2 2H13.003M13 20.486l6 5.525l10-10.292'/%3E%3C/g%3E%3C/svg%3E");
}

.gala--file-document {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round'%3E%3Cpath stroke-width='15.992' d='M 32,48 V 207.9236'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='M 224,96 V 208'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='m 64,16 h 80'/%3E%3Cpath stroke-width='15.992' d='M 64,240 H 192'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='m 224,208 c 0.0874,15.98169 -16,32 -32,32'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='m -32,208 c -10e-7,16 -16,32 -32,32' transform='scale(-1 1)'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='M -32,-47.976784 C -32,-32 -48,-16.356322 -63.999997,-16.000002' transform='scale(-1)'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='M 223.91257,96.071779 144,16'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='m -144,64 c -0.0492,15.912926 -16.06452,31.999995 -32,32' transform='scale(-1 1)'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='M 144,64 V 16'/%3E%3Cpath stroke-linejoin='round' stroke-width='15.992' d='m 176,96 h 48'/%3E%3Cpath stroke-linejoin='round' stroke-width='16' d='m 64,208 h 48'/%3E%3Cpath stroke-linejoin='round' stroke-width='16' d='m 64,176 h 80'/%3E%3Cpath stroke-linejoin='round' stroke-width='16' d='m 64,144 h 48'/%3E%3C/g%3E%3C/svg%3E");
}

.akar-icons--image {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4z'/%3E%3Ccircle cx='8.5' cy='8.5' r='2.5'/%3E%3Cpath d='M14.526 12.621L6 22h12.133A3.867 3.867 0 0 0 22 18.133V18c0-.466-.175-.645-.49-.99l-4.03-4.395a2 2 0 0 0-2.954.006'/%3E%3C/g%3E%3C/svg%3E");
}

.proicons--layers {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='M12.991 3.066a2 2 0 0 0-1.982 0L2.75 7.778l8.259 4.712a2 2 0 0 0 1.982 0l8.259-4.712z'/%3E%3Cpath stroke-linecap='round' d='m2.75 12l7.268 4.147a4 4 0 0 0 3.964 0L21.25 12'/%3E%3Cpath stroke-linecap='round' d='m2.75 16.222l7.268 4.147a4 4 0 0 0 3.964 0l7.268-4.147'/%3E%3C/g%3E%3C/svg%3E");
}

.icon-park-outline--selected {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cg fill='none' stroke='%23000' stroke-linejoin='round' stroke-width='4'%3E%3Cpath d='M12 4H4v8h8zm32 32h-8v8h8zm-32 0H4v8h8zM44 4h-8v8h8z'/%3E%3Cpath stroke-linecap='round' d='M8 36V12m32 24V12M12 8h24M12 40h24'/%3E%3C/g%3E%3C/svg%3E");
}

.material-symbols--timer-outline-rounded {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M10 3q-.425 0-.712-.288T9 2t.288-.712T10 1h4q.425 0 .713.288T15 2t-.288.713T14 3zm2 11q.425 0 .713-.288T13 13V9q0-.425-.288-.712T12 8t-.712.288T11 9v4q0 .425.288.713T12 14m0 8q-1.85 0-3.488-.712T5.65 19.35t-1.937-2.863T3 13t.713-3.488T5.65 6.65t2.863-1.937T12 4q1.55 0 2.975.5t2.675 1.45l.7-.7q.275-.275.7-.275t.7.275t.275.7t-.275.7l-.7.7Q20 8.6 20.5 10.025T21 13q0 1.85-.713 3.488T18.35 19.35t-2.863 1.938T12 22m0-2q2.9 0 4.95-2.05T19 13t-2.05-4.95T12 6T7.05 8.05T5 13t2.05 4.95T12 20m0-7'/%3E%3C/svg%3E");
}

/* 视频组件 */
.mage--video-player {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23000' stroke-width='1.5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3.196 7.873h17.608m-4.997 0V2.877M8.193 7.873V2.877m1.947 9.051v4.922c0 .101.032.2.091.286c.06.085.145.154.246.199a.66.66 0 0 0 .633-.057l3.798-2.65a.56.56 0 0 0 .176-.199a.5.5 0 0 0-.02-.492a.6.6 0 0 0-.192-.186l-3.798-2.272a.66.66 0 0 0-.616-.025a.6.6 0 0 0-.232.198a.5.5 0 0 0-.086.276'/%3E%3Crect width='18.5' height='18.5' x='2.75' y='2.75' rx='6'/%3E%3C/g%3E%3C/svg%3E");
}

/* 锚点定位 */
.iconoir--box-3d-point {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath fill='%23000' d='M3 18a1 1 0 1 0 0-2a1 1 0 0 0 0 2'/%3E%3Cpath d='M21 7.353v9.294a.6.6 0 0 1-.309.525l-8.4 4.666a.6.6 0 0 1-.582 0l-8.4-4.666A.6.6 0 0 1 3 16.647V7.353a.6.6 0 0 1 .309-.524l8.4-4.667a.6.6 0 0 1 .582 0l8.4 4.667a.6.6 0 0 1 .309.524'/%3E%3Cpath d='m3.528 7.294l8.18 4.544a.6.6 0 0 0 .583 0l8.209-4.56M12 21v-9'/%3E%3C/g%3E%3C/svg%3E");
}
</style>
