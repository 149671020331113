<template>
  <configure-item :label="label">
    <el-upload
      action
      class="upload-demo"
      :accept="options.accept"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      :limit="1"
      :http-request="customUpload"
      :file-list="fileList"
    >
      <el-button
        v-if="fileList.length === 0"
        size="mini"
        type="primary"
        plain
        icon="el-icon-upload2"
        >点击上传</el-button
      >
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { upload } from "@/utils/upload";
import filesize from "filesize";

export default {
  name: "SchemaBaseUpload",
  mixins: [schemaMixin],
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    maxFileSize() {
      const { maxFileSize } = this.options;
      if (!maxFileSize) {
        return null;
      }
      return maxFileSize * 1024 * 1024;
    },
    tip() {
      const { accept } = this.options;

      return `请上传${accept}格式的文件，大小在${filesize(this.maxFileSize, {
        standard: "jedec",
      })}以内`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newV) {
        if (!newV) {
          this.fileList = [];
          return;
        }
        const fileName = decodeURIComponent(newV.split("_")[2]);
        this.fileList = [{ name: fileName, url: newV }];
      },
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    beforeUpload(file) {
      const isLtFileMaxSize = file.size <= this.maxFileSize;

      if (!isLtFileMaxSize) {
        this.$message.error(
          `上传的文件大小不能超过 ${filesize(this.maxFileSize, { standard: "jedec" })}`
        );
      }
      return isLtFileMaxSize;
    },
    customUpload(param) {
      upload(param.file).then((res) => {
        // 触发on-success事件
        param.onSuccess(res);
      });
    },
    handleSuccess(res) {
      const { url } = res;
      this.$emit("input", url);
    },
    handleRemove() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="less" scoped></style>
