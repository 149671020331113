<template>
  <div>
    <configure-item :label="label">
      <el-radio-group v-model="mValue.showDate">
        <el-radio v-for="(item, index) in data" :key="index" :label="item.value">
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </configure-item>
    <configure-item label="倒计时前文案">
      <el-input v-model="mValue.prefix" :maxlength="4" />
    </configure-item>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaTimerLabelConfigure",

  mixins: [schemaMixin],

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>
