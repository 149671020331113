<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <div class="page-info">
        <h2>{{ activity.name }}</h2>
        <span>{{ activity.creator.name }}</span>
        <span>{{ activity.activity_id }}</span>
      </div>

      <!-- 右侧内容 -->
      <div>
        <el-button size="small ml10" type="primary" @click="show = true">实时预览</el-button>

        <el-button size="small" type="primary" @click="openSave">保存</el-button>

        <el-button size="small ml10" @click="back">退出</el-button>
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>
  </div>
</template>

<script>
import RealTimeView from "./RealTimeView.vue";
import { mapGetters, mapMutations } from "vuex";
import { submitPageInfo } from "@/api/common";
import { getActivityDetail } from "@/api/activity";
import { removeProject, verification } from "@/utils/project";
export default {
  components: {
    RealTimeView,
  },

  provide() {
    return {
      topBar: this,
    };
  },

  data() {
    return {
      show: false,
      uuid: "",
      activity: {
        name: "专题页名字",
        activity_id: "专题页ID",
        creator: {
          name: "专题页创建人",
        },
      },
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },
  mounted() {
    const uuid = this.$route.params.uuid;
    this.uuid = uuid;
    uuid && this.fetchData();
  },
  methods: {
    ...mapMutations(["setDefaultProject"]),
    back() {
      this.$confirm("是否确定退出？未保存的内容将会丢失！！！", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // removeProject();
        this.setDefaultProject();
        this.$router.go(-1);
      });
    },
    fetchData() {
      getActivityDetail(this.uuid).then((res) => {
        console.log("1313123", res.data);
        this.activity = res.data;
      });
    },
    openSave() {
      const { verify, msg } = verification(this, this.activity, this.project.componentList);
      console.log("this.project: ", this.project);
      if (verify) {
        submitPageInfo(this.uuid, {
          content: JSON.stringify(this.project),
          extra: "",
        }).then(() => {
          this.$message.success("发布成功！");
          removeProject();
          // this.$router.go(-1);
        });
      } else {
        this.$message.error(msg);
      }
    },

    toSchema() {
      let { href } = this.$router.resolve({
        path: "/schema",
      });
      window.open(href);
    },

    // 退出
    onLogout() {
      this.$confirm("是否确定退出平台?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message.success("发布成功！");
        removeProject();
        this.$router.push("/activity-list/activityList");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  .page-info {
    display: flex;
    align-items: center;
    span {
      margin-left: 20px;
    }
  }
}
</style>
