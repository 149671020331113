<template>
  <configure-item :label="label">
    <div class="wrap">
      <el-checkbox-group v-model="mValue">
        <el-checkbox v-for="item in optionList" :label="item.value" :key="item.value">{{
          item.label
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaCheckbox",
  mixins: [schemaMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    optionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkList: ["选中且禁用", "复选框 A"],
    };
  },
};
</script>
