<template>
  <div class="filter-container">
    <el-card>
      <el-input
        v-model="value.activityId"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入活动ID"
      ></el-input>
      <el-input
        v-model="value.name"
        class="filter-item"
        style="width: 150px"
        clearable
        placeholder="请输入活动名称"
      ></el-input>
      <!-- 创建人 -->
      <RemoteSelect v-model="value.creator" />
      <el-select
        style="width: 150px"
        class="filter-item"
        v-model="value.hasEnable"
        clearable
        placeholder="是否有效"
      >
        <el-option :key="1" label="是" :value="true"></el-option>
        <el-option :key="2" label="否" :value="false"></el-option>
      </el-select>
      <el-date-picker
        v-model="dateTimeRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束日期"
        :default-time="defaultTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        clearable
        style="margin: 0 10px 10px 0; vertical-align: middle"
        @change="formatDataTime"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="$emit('search')"
        >搜索</el-button
      >
    </el-card>
  </div>
</template>

<script>
import RemoteSelect from "@/components/RemoteSelect";
export default {
  components: {
    RemoteSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dateTimeRange: [],
      defaultTime: ["00:00:00", "23:59:59"],
    };
  },
  methods: {
    formatDataTime(v) {
      if (v) {
        this.value.startTime = this.dateTimeRange[0];
        this.value.endTime = this.dateTimeRange[1];
      } else {
        this.value.startTime = "";
        this.value.endTime = "";
      }
    },
  },
};
</script>

<style></style>
