<template>
  <div>
    <configure-item :label="label">
      <el-radio-group v-model="mValue.type">
        <el-radio v-for="(item, index) in data" :key="index" :label="item.value">
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </configure-item>
    <div v-if="mValue.type === 'round'">
      <div class="w99 f13 f-grey pt10">圆角角度</div>
      <div class="p20">
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px 0">
          <div style="display: flex; align-items: center">
            <div class="f-grey mr10">左上：</div>
            <el-input-number
              v-model="mValue.borderTopLeftRadius"
              :precision="0"
              :min="0"
              :max="99"
            />
          </div>
          <div style="display: flex; align-items: center">
            <div class="f-grey mr10">右上：</div>
            <el-input-number
              v-model="mValue.borderTopRightRadius"
              :precision="0"
              :min="0"
              :max="99"
            />
          </div>
          <div style="display: flex; align-items: center">
            <div class="f-grey mr10">左下：</div>
            <el-input-number
              v-model="mValue.borderBottomLeftRadius"
              :precision="0"
              :min="0"
              :max="99"
            />
          </div>
          <div style="display: flex; align-items: center">
            <div class="f-grey mr10">右下：</div>
            <el-input-number
              v-model="mValue.borderBottomRightRadius"
              :precision="0"
              :min="0"
              :max="99"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaButtonConfig",

  mixins: [schemaMixin],

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>
