<template>
  <configure-warp :title="label">
    <div class="mb10 f-grey f12">{{ mOptions.note }}</div>
    <div class="nav">
      <!-- <draggable v-model="mValue"> -->
      <!-- 编译模式,插槽可供自定义拖拽组件入容器 -->
      <slot v-if="edit"></slot>

      <!-- 非编译模式，根据schema子类遍历数组单项组件 -->
      <template v-else>
        <div v-for="(item, mIndex) in mValue" :key="item.id" class="nav-item">
          <el-collapse v-model="activeNames">
            <el-collapse-item class="wrap-title" :name="item.id">
              <template slot="title">
                {{ mOptions.barText ? mOptions.barText : "配置" }}{{ mIndex + 1 }}:
                {{ mOptions.needCopy ? item.id : "" }} &nbsp;&nbsp;
                <el-button
                  v-if="mOptions.needCopy"
                  type="info"
                  size="mini"
                  plain
                  @click.stop="copyHandler(item.id)"
                  >复制ID</el-button
                >
              </template>
              <component
                v-for="(val, key, index) in schema.child"
                :key="index"
                :is="typeToComponent[val.type]"
                v-model="item[key]"
                v-bind="val"
              >
              </component>
            </el-collapse-item>
          </el-collapse>
          <div class="nav-delete" @click="delItem(item.id)">x</div>
        </div>
      </template>
      <!-- </draggable> -->

      <div v-if="showBtn" class="nav-add" @click="addItem">
        {{ mOptions.addBtnText || "新增数据" }}
      </div>
    </div>
  </configure-warp>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import typeToComponent from "@/config/schema-template";
import { copyText } from "@/utils";

export default {
  name: "SchemaArray",

  mixins: [schemaMixin],

  props: {
    label: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    showBtn() {
      return this.mValue.length < this.mOptions?.limit;
    },
  },
  data() {
    return {
      typeToComponent,
      activeNames: [],
    };
  },
  created() {
    this.mValue.forEach((item, index) => {
      if (item.id === "$uuid") {
        this.$set(item, "id", this.$getRandomCode(6));
      }
    });
  },
  methods: {
    addItem() {
      if (this.mValue.length >= this.mOptions?.limit) {
        this.$notify({
          title: "无法新增",
          message: `最多只能添加${this.mOptions?.limit}条数据`,
          type: "warning",
        });
        return;
      }

      const result = {};
      for (const key in this.schema.child) {
        const item = JSON.parse(JSON.stringify(this.schema.child[key]));
        result[key] = item.value;
      }
      const id = this.$getRandomCode(6);
      this.activeNames[0] = id;
      this.mValue.push({
        id,
        ...result,
      });
    },

    delItem(id) {
      let i = this.mValue.findIndex((item) => item.id == id);
      this.mValue.splice(i, 1);
    },
    copyHandler(v) {
      copyText(v);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .config-item {
  margin-bottom: 10px !important;
}
::v-deep .nav .nav-item {
  padding: 0 10px;
}
::v-deep .el-collapse {
  border-top: none;
}
::v-deep .el-collapse-item__header {
  border-bottom: none;
}
</style>
