import { render, staticRenderFns } from "./SidebarItem.vue?vue&type=template&id=f74e678c"
import script from "./SidebarItem.vue?vue&type=script&lang=js"
export * from "./SidebarItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_6m5rqjauvbyej62p7eiecas4wq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports