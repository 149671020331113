<template>
  <div class="app-container">
    <SearchForm v-model="queryParams" @search="fetchData" />
    <el-button
      v-permission="['course.add_course']"
      type="primary"
      icon="el-icon-plus"
      @click="$refs.edit.handleEdit('create')"
      style="margin-bottom: 10px"
      >创建</el-button
    >
    <el-card>
      <el-table
        v-loading.body="tableLoading"
        :data="tableData"
        element-loading-text="加载中 ..."
        border
        header-align="center"
        highlight-current-row
      >
        <el-table-column
          label="活动ID"
          min-width="80px"
          prop="activity_id"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="活动名称"
          min-width="200"
          prop="name"
          align="center"
          header-align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="是否有效"
          min-width="80px"
          prop="has_enable"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span class="warn" v-if="scope.row.has_enable">有效</span>
            <span class="info" v-else>无效</span>
          </template>
        </el-table-column>
        <el-table-column
          label="链接"
          min-width="300"
          prop="link"
          header-align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a :href="scope.row.link" target="_blank">{{ scope.row.link }}</a>
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          min-width="80px"
          prop="creator.name"
          align="center"
          header-align="center"
        />
        <el-table-column prop="create_time" label="创建时间" min-width="310" align="center" />
        <el-table-column
          label="操作"
          min-width="300px"
          align="center"
          header-align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-permission="['zt.design_zt']"
              type="text"
              size="small"
              @click="goEdit(scope.row)"
              >页面配置</el-button
            >
            <el-button
              v-permission="['course.modify_course']"
              type="text"
              size="small"
              @click="$refs.edit.handleEdit('edit', scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              v-permission="['ca.shelf_ca']"
              :title="`确定要${scope.row.has_enable ? '下架' : '上架'}该专题页吗？`"
              style="margin: 0 10px"
              @confirm="handleDisplay(scope.row)"
            >
              <el-button slot="reference" type="text" size="small">
                {{ scope.row.has_enable ? "下架" : "上架" }}
              </el-button>
            </el-popconfirm>
            <el-button
              v-permission="['ca.log_ca']"
              type="text"
              size="small"
              @click="$refs.operationLog.showLogs(scope.row.uuid)"
              >日志</el-button
            >
            <el-button
              v-permission="['course.copy_course']"
              type="text"
              size="small"
              @click="$refs.edit.handleEdit('copy', scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <pagination
          :total="count"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="fetchData"
        />
      </div>
    </el-card>

    <edit ref="edit" />
    <operation-log ref="operationLog" />
    <PrizeStatistical ref="prizeStatistical" />
  </div>
</template>

<script>
import PrizeStatistical from "../luckDraw/components/PrizeStatistical";
import SearchForm from "./components/SearchForm.vue";
import Edit from "./components/Edit.vue";
import OperationLog from "./components/OperationLog.vue";
import { getCourseList, toggleDisplay } from "@/api/course";
import { copyText } from "@/utils";
export default {
  components: {
    PrizeStatistical,
    SearchForm,
    Edit,
    OperationLog,
  },
  data() {
    return {
      count: 0,
      tableLoading: false,
      queryParams: {
        page: 0,
        size: 10,
        uuid: "",
        name: null,
        creator: null,
        platform: "H5",
        is_active: undefined,
        start_time: "",
        end_time: "",
      },
      typeArray: [{ label: "H5", value: "H5" }],
      tableData: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    goH5Page(row) {
      copyText(row.link);
      process.env.NODE_ENV === "development"
        ? window.open("https://h5.kaoyan-vip.cn/admin/channel/", "_blank")
        : window.open("https://h5.kaoyanvip.cn/admin/channel/", "_blank");
    },
    goEdit(row) {
      this.$router.push({
        path: `/generator/${row.uuid}`,
        query: {
          产品模块: null,
          功能模块: ["跳转组件", "静态图片", "tab组件", "视频组件", "锚点定位"],
        },
      });
    },
    async fetchData() {
      const res = await getCourseList(this.queryParams);
      this.tableData = res.data.results;
      this.count = res.data.count;
    },
    async handleDisplay(v) {
      await toggleDisplay(v.uuid);
      this.$parent.$message({
        message: v.has_enable ? "下架活动成功" : "上架活动成功",
        type: "success",
      });
      this.fetchData();
    },
  },
};
</script>

<style></style>
