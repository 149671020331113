<template>
  <div class="wrap">
    <el-collapse v-model="activeNames">
      <el-collapse-item class="wrap-label" name="1">
        <template slot="title"> <i class="cus-header-icon"></i> {{ label }}</template>
        <span v-if="labelTip" class="f-grey" style="margin-left: 20px">{{ labelTip }}</span>
        <div class="wrap-body">
          <slot></slot>
          <div v-show="line" class="wrap-line"></div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "SchemaObject",
  props: {
    label: {
      type: String,
      default: "",
    },
    labelTip: {
      type: String,
      default: "",
    },
    line: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNames: ["1"],
    };
  },
  // created() {
  //   console.log("Object=>label", this.label);
  //   console.log("Object=>line", this.line);
  // },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .wrap-label {
    ::v-deep .el-collapse-item__header {
      .cus-header-icon::before {
        color: #409eff;
        content: ".";
        width: 3px;
        height: 10px;
        margin: 0 8px;
        background: #409eff;
      }
    }
  }

  .wrap-body {
    padding: 20px 0 10px 20px; /*no*/

    .wrap-line {
      margin-top: 30px;
      height: 1px;
      background: #ebedf0;
    }
  }
}
</style>
